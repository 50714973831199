import { apiSlice } from "./apiSlice";
import {
  createMultipleSuppliers,
  createMultipleWarehouses,
  createWarehouses,
  getBottomDifotMetrics,
  getBottomLeadMetrics,
  getDownloadedSuppliers,
  getDownloadedWarehouses,
  getMetricsCriteria,
  getSupplierMetrics,
  getSuppliers as getSuppliersReducer,
  getSuppliersFields,
  getTopDifotMetrics,
  getTopLeadMetrics,
  getTopSuppliersSpend,
  getWarehouseBasedWareCosting,
  getWarehouseFields,
  getWarehouses,
  getWarehouseType,
  setSuppliersSelection,
  setWarehouse,
  updateMultipleSuppliers,
  updateMultipleWarehouses,
  updateWarehouse,
} from "../slice/supplierSlice";

export const purchaseApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createMetricsCriteria: builder.mutation({
      query: data => ({ url: "/api_supplier/metrics_criteria/", method: "post", body: data }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getMetricsCriteria(result.data.data)))
          .catch(error => {});
      },
    }),
    createMultipleSuppliers: builder.mutation({
      query: data => ({ url: "/api_supplier/supplier/bulk_create/", method: "post", body: data }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(createMultipleSuppliers(result.data.data)))
          .catch(error => {});
      },
    }),
    createMultipleWarehouses: builder.mutation({
      query: data => ({
        url: "/api_supplier/warehouse/create_multiple_warehouses/",
        method: "post",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(createMultipleWarehouses(result.data.data)))
          .catch(error => {});
      },
    }),
    createSuppliers: builder.mutation({
      query: data => ({ url: "/api_supplier/supplier/", method: "post", body: data }),
    }),
    createWarehouse: builder.mutation({
      query: data => ({
        url: "/api_supplier/warehouse/create_warehouse/",
        method: "post",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(createWarehouses(result.data.data)))
          .catch(error => {});
      },
    }),
    getDifotMetrics: builder.query({
      query: params => `/api_supplier/difot_metrics/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => {
            dispatch(getTopSuppliersSpend(result.data.data.top_spend));
            dispatch(getTopDifotMetrics(result.data.data.top_difot));
            dispatch(getBottomDifotMetrics(result.data.data.bottom_difot));
          })
          .catch(error => {});
      },
    }),
    getDownloadedSuppliers: builder.query({
      query: params =>
        params
          ? `/api_supplier/supplier/download_records/?${params}`
          : "/api_supplier/supplier/download_records/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getDownloadedSuppliers(result.data.data)))
          .catch(error => {});
      },
    }),
    getDownloadedWarehouses: builder.query({
      query: params =>
        params
          ? `/api_supplier/warehouse/download_records/?${params}`
          : "/api_supplier/warehouse/download_records/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getDownloadedWarehouses(result.data.data)))
          .catch(error => {});
      },
    }),
    getLeadTimeMetrics: builder.query({
      query: params => `/api_supplier/lead_time_metrics/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => {
            dispatch(getTopLeadMetrics(result.data.data.top_leads));
            dispatch(getBottomLeadMetrics(result.data.data.bottom_leads));
          })
          .catch(error => {});
      },
    }),
    getMetricsCriteria: builder.query({
      query: () => "/api_supplier/metrics_criteria/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getMetricsCriteria(result.data.data)))
          .catch(error => {});
      },
    }),
    getSupplierMetrics: builder.query({
      query: params => `/api_supplier/supplier_metrics/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getSupplierMetrics(result.data.data)))
          .catch(error => {});
      },
    }),
    getSuppliers: builder.query({
      query: (params = null) =>
        params ? `/api_supplier/supplier/?${params}` : "/api_supplier/supplier/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getSuppliersReducer(result.data)))
          .catch(error => {});
      },
    }),
    getSuppliersFields: builder.query({
      query: () => "/api_supplier/suppliers_fields/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(getSuppliersFields(result.data))).catch(error => {});
      },
    }),
    getSuppliersSelection: builder.query({
      query: params =>
        params
          ? `/api_supplier/supplier/?model=selection&${params}`
          : `/api_supplier/supplier/?model=selection`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(setSuppliersSelection(result.data)))
          .catch(error => {});
      },
    }),
    getWarehouse: builder.query({
      query: params => (params ? `/api_supplier/warehouse/?${params}` : "/api_supplier/warehouse/"),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(getWarehouses(result.data))).catch(error => {});
      },
    }),
    getWarehouseBasedWareCosting: builder.query({
      query: (params = null) => `/api_supplier/get_warehouses_warehouse_costing/`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getWarehouseBasedWareCosting(result.data.data)))
          .catch(error => {});
      },
    }),
    getWarehouseDetail: builder.query({
      query: params => `/api_supplier/warehouse/${params}/`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(setWarehouse(result.data))).catch(error => {});
      },
    }),
    getWarehouseFields: builder.query({
      query: () => "/api_supplier/warehouse_fields/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(getWarehouseFields(result.data))).catch(error => {});
      },
    }),
    getWarehouseType: builder.query({
      query: () => "/api_supplier/warehouse_type/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(getWarehouseType(result.data))).catch(error => {});
      },
    }),
    updateMetricsCriteria: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api_supplier/metrics_criteria/${id}/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getMetricsCriteria(result.data.data)))
          .catch(error => {});
      },
    }),
    updateMultipleSuppliers: builder.mutation({
      query: data => ({ url: "/api_supplier/supplier/bulk_update/", method: "PATCH", body: data }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateMultipleSuppliers(result.data.data)))
          .catch(error => {});
      },
    }),
    updateMultipleWarehouses: builder.mutation({
      query: data => ({
        url: "/api_supplier/warehouse/update_downloaded_warehouses/",
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateMultipleWarehouses(result.data.data)))
          .catch(error => {});
      },
    }),
    updateSuppliers: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api_supplier/supplier/${id}/`,
        method: "PATCH",
        body: data,
      }),
    }),
    updateWarehouse: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api_supplier/warehouse/${id}/update_warehouse/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateWarehouse(result.data.data)))
          .catch(error => {});
      },
    }),
  }),
});

export const {
  useCreateMetricsCriteriaMutation,
  useCreateMultipleSuppliersMutation,
  useCreateMultipleWarehousesMutation,
  useCreateSuppliersMutation,
  useCreateWarehouseMutation,
  useGetDifotMetricsQuery,
  useGetLeadTimeMetricsQuery,
  useGetMetricsCriteriaQuery,
  useGetSupplierMetricsQuery,
  useGetSuppliersFieldsQuery,
  useGetSuppliersQuery,
  useGetSuppliersSelectionQuery,
  useGetWarehouseBasedWareCostingQuery,
  useGetWarehouseDetailQuery,
  useGetWarehouseFieldsQuery,
  useGetWarehouseQuery,
  useGetWarehouseTypeQuery,
  useLazyGetDownloadedSuppliersQuery,
  useLazyGetDownloadedWarehousesQuery,
  useLazyGetSuppliersQuery,
  useLazyGetWarehouseDetailQuery,
  useLazyGetWarehouseQuery,
  useUpdateMetricsCriteriaMutation,
  useUpdateMultipleSuppliersMutation,
  useUpdateMultipleWarehousesMutation,
  useUpdateSuppliersMutation,
  useUpdateWarehouseMutation,
} = purchaseApiSlice;
