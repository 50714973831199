import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  acquisitionCodes: [],
  freezePanel: { columns: 0, dataTable: "" },

  plannedOrders: { count: 0, results: [] },
  plannedOrdersParams: { limit: 10, offset: 0 },

  plannedOrderStatus: [],

  projectedStocks: { count: 0, results: [] },
  projectedStockParams: { limit: 10, offset: 0 },

  plannedOrdersProducts: [],
};

const plannerSlice = createSlice({
  name: "planner",
  initialState,
  reducers: {
    confirmPlannedOrders: (state, action) => {
      const { ids } = action.payload;
      const idSet = new Set(ids);
      state.plannedOrders.results.forEach(plannedOrder => {
        if (idSet.has(plannedOrder.id)) {
          plannedOrder.status = "Confirmed";
        }
      });
    },
    getAcquisitionCodes: (state, action) => {
      state.acquisitionCodes = action.payload;
    },
    getPlannedOrders: (state, action) => {
      state.plannedOrders = action.payload;
    },
    getPlannedOrdersProducts: (state, action) => {
      state.plannedOrdersProducts = action.payload;
    },
    getPlannedOrderStatus: (state, action) => {
      state.plannedOrderStatus = action.payload;
    },
    getProjectedStocks: (state, action) => {
      state.projectedStocks = action.payload;
    },
    resetFreezePanel: (state, action) => {
      state.freezePanel = initialState.freezePanel;
    },
    resetPlannedOrders: state => {
      state.plannedOrders = initialState.plannedOrders;
    },
    resetPlannedOrdersParams: state => {
      state.plannedOrdersParams = { limit: state.plannedOrdersParams.limit, offset: 0 };
    },
    resetProjectedStocksParams: state => {
      state.projectedStockParams = { limit: state.projectedStockParams.limit, offset: 0 };
    },
    setFreezePanel: (state, action) => {
      state.freezePanel = action.payload;
    },
    setPlannedOrdersParams: (state, action) => {
      state.plannedOrdersParams = action.payload;
    },
    setProjectedStocksParams: (state, action) => {
      state.projectedStockParams = action.payload;
    },
    updatePlannedOrdersParams: (state, action) => {
      state.plannedOrdersParams = { ...state.plannedOrdersParams, ...action.payload };
    },
    updateProjectedStocks: (state, action) => {
      state.projectedStocks = { ...state.projectedStocks, ...action.payload };
    },
    updateProjectedStocksParams: (state, action) => {
      state.projectedStockParams = { ...state.projectedStockParams, ...action.payload };
    },
  },
});

export const {
  confirmPlannedOrders,
  getAcquisitionCodes,
  getPlannedOrders,
  getPlannedOrdersProducts,
  getPlannedOrderStatus,
  getProjectedStocks,
  resetFreezePanel,
  resetPlannedOrders,
  resetPlannedOrdersParams,
  resetProjectedStocksParams,
  setFreezePanel,
  setPlannedOrdersParams,
  setProjectedStocksParams,
  updatePlannedOrdersParams,
  updateProjectedStocks,
  updateProjectedStocksParams,
} = plannerSlice.actions;

export default plannerSlice.reducer;
